// Reset
@import "_reset";

// Fonts
@import "_fonts";

// Lib
@import "_lib";


/* *+*+*+*+*+*+*+*+*+ [start] Viewport Screen +*+*+*+*+*+*+*+*+* */
$motoG4_width:          360px;     $motoG4_height:          640px;
$GalaxyS5_width:        360px;     $GalaxyS5_height:        640px;
$pixel2_width:          411px;     $pixel2_height:          731px;
$iPhone5-se_width:      320px;     $iPhone5-se_height:      568px;
$iPhone6-7-8_width:     375px;     $iPhone6-7-8_height:     667px;
$iPhone6-7-8Plus_width: 414px;     $iPhone6-7-8Plus_height: 736px;
$iPhoneX_width:         375px;     $iPhoneX_height:         812px;
/* +*+*+*+*+*+*+*+*+*+ [end] Viewport Screen +*+*+*+*+*+*+*+*+*+ */

$headerHeight: 60px;

$headerFontColor: #666;
$componentFontColor: #30a0c1;


html {
    background-color: #FFF;
    overflow: hidden;
}
.wrap-container {
    display: flex;
}
.wrap-screen {
    position: relative;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #F7F7F7;
    overflow: auto;
}

.loader {
    background-color: rgba(255,255,255,0.5);
    background-color: rgba(0,0,0,0.7);
    // background-color: #8B7F4E;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    svg, img {        
        position: absolute;
        width: 150px !important;
        height: 150px !important;
        top: 50%;
        left: 50%;
        margin-top: -75px;
        margin-left: -75px;        
    }
}
.slideOutRight {
    margin-left: -100%;
}

// header
.header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 40px;
    padding: 10px;
    border-bottom: 1px solid #CCC;
    background-color: #FFF;
    z-index: 1000;
    display: flex;
    justify-content: center;
    .btn-view {
        margin-right: 30px;
        div {
            display: inline-block;
            font-size: 12px;
            margin: 5px 15px;
            // color: #DDD;
            color: $headerFontColor;
            cursor: pointer;
            span {
                font-weight: 700;
            }
        }
        .active {
            color: #777;
        }
    }
    .progress {
        display: flex;
        .section {
            font-size: 10px;
            margin-right: 15px;
            label {
                display: block;
                margin-bottom: 5px;
                color: #777;
            }
        }
        div {
            display: flex;
        }
        ul {
            display: flex;
        }
        li {
            padding: 5px 0px;
            margin: 0px 15px;
            cursor: pointer;
        }
        .all {
            // margin-right: 50px;
        }
        .list-active {
            opacity: 1;
        }
        .list-inactive {
            opacity: 0.5;
        }
        .symbol {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            margin-right: 5px;
        }
        .text {
            font-size: 11px;
            font-weight: 700;
        }
        ul {
            li {
                margin-bottom: 5px;
                color: #777;
            }
            .inactive {
                .symbol {
                    background-color: #CCC;
                }
                .text {
                    color: #777;
                }
            }
        }
                
    }
    .update {
        position: fixed;
        right: 60px;
        line-height: 41px;
        font-size: 12px;
        > span { font-size: 0.6em; }
    }
}
.header-space {
    position: relative;
    // margin-top: 20px;
    width: 100%;
    height: 60px;
}
.shortcut {
    display: block;
    width: 100%;
    margin-top: 5px;
    text-align: center;
    font-weight: 700;
    font-size: 10px;
    // color: #DDD;
    color: $headerFontColor;
}

/* +*+*+*+*+* viewport *+*+*+*+**+ */
.viewport-wrap {
    margin: auto 0;
    height: 25px; line-height: 25px;
    overflow: hidden;
    background-color: #EFEFEF;
    text-align: center;
    &:hover {
        overflow: visible;
    }
    
}
.viewport-option {
    font-size: 12px;
    height: 25px;
    overflow: hidden;
    &:hover {
        height: 225px;
        transition: 0.5s ease-out;
    }
    a{
        display: block;
        padding: 0px 6px;
        background-color: #EFEFEF;
        color: $componentFontColor;
        &:hover {
            color: #000;
            transition-duration: 0.4s;
        }
    }
}
/* +*+*+*+*+* viewport *+*+*+*+**+ */

.page-wrap {
    position: fixed;
    bottom: 40px; right: 40px;
    font-size: 20px;
}

// card
.screen-card {
    .screen {
        position: absolute;
        width: 55px;
        height: 100px;
        /* background-color: #F9c; */
        background-color: #FFF;
        border: 1px solid #AAA;
        margin: 100px;
        font-size: 12px;    
        font-weight: 600;
        cursor: default;
        .thumb {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100px;
            background-color: #EEE;
            outline: none;
            transition: 0.3s;
            cursor: pointer;
            .slide {
                outline: none;
                img {
                    object-fit: cover;
                    object-position: top;
                    width: 55px;
                    height: 100px;
                    background-color: #fff;
                }
            }
        }
        .thumb:hover {
            opacity: 0.5;
        }
        .code {
            position: absolute;
            top: -35px;
            left: 0px;
            font-size: 10px;
            color: #777;
            // width: 150px;
        }
        .update-count {
            cursor: pointer;
            &:hover{
                .last-changed {
                    // display: inline-block;
                    opacity: 1;
                }
            }
            .changed {
                position: absolute;
                top: -35;
                left: 35px;
                font-size: 10px;
                color: red;
                width: 100%;
            }
            .last-changed {
                cursor: default;
                // display: none;
                position: absolute;
                top: -35px;
                left: 100px;
                font-size: 10px;
                font-weight: normal;
                color: blue;
                width: 65px;
                text-align: right;
                line-height: 14px;
                transition-duration: 0.5s;
                opacity: 0;
            }
        }
        .title {
            position: absolute;
            top: -20px;
            left: 0px;
            font-size: 11px;
            color: #333;
            width: 150px;
        }
        .info {
            position: absolute;
            bottom: -30px;
            left: 0px;
            .component {
                font-size: 10px;
                color: $componentFontColor;
            }
            .router {
                font-size: 10px;
                margin-top: 2px;
                color: #777;
            }
        }
        .progress {
            position: absolute;
            top: 0px;
            left: 65px;
            width: 100px;            
            li {
                display: flex;
                border: 1px solid #CCC;
                padding: 2px 5px;
            }
            .symbol {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                margin-right: 5px;
            }
            .text {
                font-size: 10px;
            }
            ul {
                li {
                    margin-bottom: 5px;
                    color: #777;
                }
                .inactive {
                    border: 1px solid #DDD;
                    .symbol {
                        background-color: #CCC;
                    }
                    .text {
                        color: #CCC;
                    }
                }
            }
                    
        }
    }
}

.screen-list {
    .screen {
        position: relative;
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid #DDD;
        background-color: #FFF;
        font-size: 12px;    
        font-weight: 600;
        cursor: default;
        .thumb {
           display: none;
        }
    
        .code {
            position: relative;
            font-size: 10px;
            color: #777;
            width: 150px;
        }
        .title {
            position: relative;
            font-size: 14px;
            color: #333;
            margin-top: 5px;
        }
        .info {
            position: relative;
            margin-top: 5px;
            .component {
                font-size: 10px;
                color: #30a0c1;
            }
            .router {
                font-size: 10px;
                margin-top: 2px;
                color: #777;
            }
        }
        .progress {
            position: relative;
            margin-top: 15px;
            ul {
                display: flex;                
            }
            li {
                display: flex;
                border: 1px solid #777;
                padding: 2px 5px;
            }
            .symbol {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                margin-right: 5px;
            }
            .text {
                font-size: 10px;
            }
            ul {
                li {
                    margin-bottom: 5px;
                    color: #777;
                }
                .inactive {
                    border: 1px solid #DDD;
                    .symbol {
                        background-color: #CCC;
                    }
                    .text {
                        color: #CCC;
                    }
                }
            }
                    
        }
    }
}


.all {
    .symbol {
        background-color: purple;
    }
}
.none {
    .symbol {
        background-color: #b78649;
    }
}
.design {
    .symbol {
        background-color: gold;
    }
}
.publish {
    .symbol {
        background-color: #ff85b8;
    }
}
.development {
    .symbol {
        background-color: #6cb6ea;
    }
}
.android {
    .symbol {
        background-color: #00dc00;
    }
}
.ios {
    .symbol {
        background-color: #757575;
    }
}


.wrap-detail {
    padding-top: 20px;
    height: calc(100vh - 60px);
    overflow: auto;

    h1 {
        margin: 0 auto;
        width: 300px;
        font-size: 20px;
        line-height: 24px;
        &:nth-child(1) { font-size: 1rem; color: #333; }
        &:nth-child(2) { font-weight: bold; }
        &:nth-child(3) { color: $componentFontColor; }
    }

    .device {
        margin: 10px auto 20px;
        overflow-y: scroll;
        border: 8px solid #333;
        border-radius: 15px;
        .screen {
            width: 100%;
        }
    }
    // Device Lists
    .motoG4          { width: $motoG4_width;            height: $motoG4_height;          }
    .galaxyS5        { width: $GalaxyS5_width;          height: $GalaxyS5_height;        }
    .pixel2          { width: $pixel2_width;            height: $pixel2_height;          }
    .iPhone5-se      { width: $iPhone5-se_width;        height: $iPhone5-se_height;      }
    .iPhone6-7-8     { width: $iPhone6-7-8_width;       height: $iPhone6-7-8_height;     }
    .iPhone6-7-8Plus { width: $iPhone6-7-8Plus_width;   height: $iPhone6-7-8Plus_height; }
    .iPhoneX         { width: $iPhoneX_width;           height: $iPhoneX_height;         }





    .drafts {  
        margin: 0 auto 50px;
        text-align: center;
        display: flex;
        justify-content: center; //FlexBox center align
        .slide {
            cursor: pointer;
            padding: 20px;
            width: 100px;
            // height: 200px;
            margin-right: 30px;
            // background-color: #efefef;
            &:last-child { margin-right: 0; }
            &:hover{
                // border: 1px solid red;
                background-color: #ddd;
                transition-duration: 0.3s;
            }
            h6 {
                text-align: center;
                font-size: 12px;
                margin: 10px 0px;
            }
            img {
                width: 100%;
                // height: 100%;
                height: 200px;
                object-fit: cover;
                object-position: top;
                border: 4px solid #777;
                border-radius: 8px;
                box-sizing: border-box;
            }
        }
        .selected-slide{
            background-color: #333;
        }
    }
    
}